"use client";

import { HardRefresh } from "@faire/next-utils/client/HardRefresh";
import { PageNotFound } from "@faire/retailer-visitor-shared/components/PageNotFound/PageNotFound";
import { RouteMatcher } from "@faire/web--source/common/routes/RouteMatcher";
import { route as bounceRoute } from "@faire/web-api--source/routes/www/bounce";
import { usePathname } from "next/navigation";
import React from "react";

const Default = () => {
  const pathname = usePathname();
  const match = new RouteMatcher([bounceRoute]).match(pathname);

  // Next.js is not supposed to handle /bounce route, so we need to hard refresh
  // /bounce route is handled by backend-monolith, look for bounce.html
  if (match) {
    return <HardRefresh />;
  }
  return <PageNotFound />;
};
export default Default;

"use client";

import { getLocationOrThrow } from "@faire/web--source/common/globals/getLocation";
import { isLocal } from "@faire/web--source/common/user-agent/isLocal";
import { notFound } from "next/navigation";
import { useEffect } from "react";

export const HardRefresh = () => {
  useEffect(() => {
    if (isLocal()) {
      // eslint-disable-next-line no-console
      console.error(
        "You are trying to visit a page that is not part of the Next.js app. While this page will work in staging and production, it will not work locally."
      );
      return notFound();
    } else {
      getLocationOrThrow().reload();
    }
  }, []);
  return null;
};
